/*
 * @Descripttion: 作业考试模块接口
 * @Author: Dyf
 * @Date: 2023-04-12
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-11-17 16:30:44
 */
import HTTP from "@utils/http";
import { PAGE_SIZE } from "@config";

const PREFIX = "/teacher/teahomework/"; // 作业
const PREFIX2 = "/teacher/teaexamination/"; // 考试
const PREFIX3 = "/teacher/teaquestion/"; // 题库中心
const PREFIX4 = "/teacher/teapaper/"; // 试卷管理（我的）
const PREFIX5 = "/teacher/tealessonspractice/"; // 校内练习


/*******
 * @Descripttion: 获取评语列表
 * @Author: Dyf
 * @param {number} type 评语类型 10. 考试  20. 作业
 * @return {*}
 */
export async function $getRemarks(type) {
  try {
    return await HTTP.post("teacher/stuhomework/get_remark", { type });
  } catch (error) {
    return error;
  }
}

/* 作业 */

/*******
 * @Descripttion: 作业列表
 * @Author: Dyf
 * @param {object} params
 * @param {number} pagesize 每页显示条数
 * @return {*}
 */
export async function $homeworkList(params, pagesize = PAGE_SIZE) {
  try {
    return await HTTP.post(
      `${PREFIX}list`,
      { ...params, pagesize },
      { load: true }
    );
  } catch (error) {
    return error;
  }
}
/*******
 * @Descripttion: 作业列表
 * @Author: Dyf
 * @param {object} params
 * @param {number} pagesize 每页显示条数
 * @return {*}
 */
export async function $schoolworkList(params, pagesize = PAGE_SIZE) {
  try {
    return await HTTP.post(
      `${PREFIX}schoollist`,
      { ...params, pagesize },
      { load: true }
    );
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 作业详情
 * @Author: Dyf
 * @param {number} teles_hom_id 作业id
 * @return {*}
 */
export async function $homeworkDetail(teles_hom_id) {
  try {
    return await HTTP.post(`${PREFIX}info`, { teles_hom_id });
  } catch (error) {
    return error;
  }
}
/*******
 * @Descripttion: 开启ap,准备下发作业
 * @Author: Dyf
 * @param {number} params 作业id
 * @return {*}
 */
export async function $attendWork(params) {
  try {
    return await HTTP.post(`${PREFIX}attend_work`,  params );
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 新增作业
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $addHomework(params) {
  try {
    return await HTTP.post(`${PREFIX}add`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 编辑作业
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $editHomework(params) {
  try {
    return await HTTP.post(`${PREFIX}edit`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 下发作业
 * @Author: Dyf
 * @param {number} params.teles_hom_id 作业id
 * @param {number} params.teles_hom_deadlinetime 作业截至提交时间
 * @return {*}
 */
export async function $distributeHomework(params) {
  try {
    return await HTTP.post("teacher/stuhomework/job_assign_homework", params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 删除作业
 * @Author: Dyf
 * @param {number} teles_hom_id 作业id
 * @return {*}
 */
export async function $delHomework(teles_hom_id) {
  try {
    return await HTTP.post(`${PREFIX}delete`, { teles_hom_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取学生数据(家庭作业)
 * @Author: Dyf
 * @param {number} teles_hom_id 作业id
 * @return {*}
 */
export async function $getHomeworkStus(teles_hom_id) {
  try {
    return await HTTP.post(`${PREFIX}get_stu_work`, { teles_hom_id });
  } catch (error) {
    return error;
  }
}
/*******
 * @Descripttion: 获取学生数据（校内作业）
 * @Author: Dyf
 * @param {number} teles_hom_id 作业id
 * @return {*}
 */
export async function $getSchoolworkStus(teles_hom_id) {
  try {
    return await HTTP.post(`${PREFIX}getStuschoolwork`, { teles_hom_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取学生作答作业信息
 * @Author: Dyf
 * @param {number} sthom_wor_id 学生作答作业id
 * @return {*}
 */
export async function $studentAnswerHomework(sthom_wor_id) {
  try {
    return await HTTP.post(`${PREFIX}get_question_list`, { sthom_wor_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取学生作答作业信息(校内作业)
 * @Author: Dyf
 * @param {number} teles_hom_id 学生作答作业id
 * @param {number} stuser_id 学生id
 * @return {*}
 */
export async function $studentAnswerSchoolwork(telesHomId, stuserId) {
  try {
    return await HTTP.post(`${PREFIX}getSchoolQuestionList`, { teles_hom_id: telesHomId, stuser_id: stuserId });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 判断作业作答是否正确
 * @Author: Dyf
 * @param {number} sthom_que_id 题目id
 * @param {number} type 判断类型 1：正确  2：错误  3：半对
 * @return {*}
 */
export async function $determineHomeworkQues(sthom_que_id, type) {
  try {
    return await HTTP.post(`${PREFIX}correct`, { sthom_que_id, type });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 批阅作业
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $readHomeworkQues(params) {
  try {
    return await HTTP.post(`${PREFIX}read_over`, params);
  } catch (error) {
    return error;
  }
}

/* 考试 */

/*******
 * @Descripttion: 考试列表
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $examList(params) {
  try {
    return await HTTP.post(`${PREFIX2}list`, params, { load: true });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取可用考试科目
 * @Author: Dyf
 * @param {number} params.schedi_sub_grade
 * @param {number} params.schedi_sub_semester
 * @return {*}
 */
export async function $examSubject(params) {
  try {
    return await HTTP.post(`${PREFIX2}now_subject`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取可用试卷列表
 * @Author: Dyf
 * @param {number} params.tepap_grade 年级
 * @param {number} params.tepap_semester 学期
 * @param {number} params.sysub_id 科目id
 * @return {*}
 */
export async function $examPaper(params) {
  try {
    return await HTTP.post(`${PREFIX2}sure_paper`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 添加考试
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $addExam(params) {
  try {
    return await HTTP.post(`${PREFIX2}add`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 考试详情
 * @Author: Dyf
 * @param {number} teexa_id 考试id
 * @return {*}
 */
export async function $getExam(teexa_id) {
  try {
    return await HTTP.post(`${PREFIX2}info`, { teexa_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 编辑考试
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $editExam(params) {
  try {
    return await HTTP.post(`${PREFIX2}edit`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 删除考试
 * @Author: Dyf
 * @param {number} teexa_id 考试id
 * @return {*}
 */
export async function $delExam(teexa_id) {
  try {
    return await HTTP.post(`${PREFIX2}delete`, { teexa_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 下发考试
 * @Author: Dyf
 * @param {number} teexa_id 考试id
 * @return {*}
 */
export async function $distributeExam(teexa_id) {
  try {
    return await HTTP.post(`${PREFIX2}do_examination`, { teexa_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 考试学生列表
 * @Author: Dyf
 * @param {number} teexa_id 试卷id
 * @return {*}
 */
export async function $examStudents(teexa_id) {
  try {
    return await HTTP.post(`${PREFIX2}student_list`, { teexa_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 学生答卷信息
 * @Author: Dyf
 * @param {number} teexa_id 试卷id
 * @param {number} stuser_id 学生id
 * @return {*}
 */
export async function $studentAnswerExam(teexa_id, stuser_id) {
  try {
    return await HTTP.post(`${PREFIX2}marking_paper`, { teexa_id, stuser_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 批阅试卷
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $determineExamQues(params) {
  try {
    return await HTTP.post(`${PREFIX2}teacher_readover`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 批阅完成
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $finishReadExam(params) {
  try {
    return await HTTP.post(`${PREFIX2}finish_readover`, params);
  } catch (error) {
    return error;
  }
}

/* 题库中心 */

/*******
 * @Descripttion: 题库中心列表
 * @Author: Dyf
 * @param {object} params
 * @param {number} pagesize 每页展示条数
 * @return {*}
 */
export async function $getQuesGroup(params, pagesize = PAGE_SIZE) {
  try {
    return await HTTP.post(
      "/teacher/teauser/teacher_core",
      {
        ...params,
        pagesize,
      },
      { load: true, loadEl: ".page-inner" }
    );
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取题目列表
 * @Author: Dyf
 * @param {number} type 题目列表类型 1.我的  2.学校  3.平台
 * @param {object} params 筛选参数
 * @param {number} pagesize 每页条数
 * @return {*}
 */
export async function $getQuesList(type, params, pagesize = PAGE_SIZE) {
  let url = "";
  switch (Number(type)) {
    case 2:
      url = "/school/schquestionscschid/list";
      break;
    case 3:
      url = "/platform/plaquestion/list";
      break;
    default:
      url = `${PREFIX3}list`;
      break;
  }
  try {
    return await HTTP.get(url, { ...params, pagesize });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 章节数据(筛选)
 * @Author: Dyf
 * @param {number} params.sccou_id 题库id(教材版本id)
 * @param {number} params.sccou_cha_pid 章id
 * @param {string} params.chapter_word 关键字
 * @return {*}
 */
export async function $getQuesChapter(params) {
  try {
    return await HTTP.post(`${PREFIX3}courses_chapter`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 下载题目模板
 * @Author: Dyf
 * @return {*}
 */
export async function $downloadTemplate(params) {
  try {
    return await HTTP.post(`${PREFIX3}importtemplate`,params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 导入题目数据
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $importQues(params) {
  try {
    return await HTTP.post(`${PREFIX3}import`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 题目详情
 * @Author: Dyf
 * @param {number} teque_id 题目id
 * @return {*}
 */
export async function $quesDetail(teque_id) {
  try {
    return await HTTP.post(`${PREFIX3}info`, { teque_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 添加题目
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $addQues(params) {
  try {
    return await HTTP.post(`${PREFIX3}add`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 编辑题目
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $editQues(params) {
  try {
    return await HTTP.post(`${PREFIX3}edit`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 删除题目
 * @Author: Dyf
 * @param {number} teque_id 题目id
 * @return {*}
 */
export async function $delQues(teque_id) {
  try {
    return await HTTP.post(`${PREFIX3}delete`, { teque_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 收藏题目
 * @Author: Dyf
 * @param {number} params.plque_ban_id 题库id
 * @param {number} params.plque_ban_type 题库类型
 * @param {number} params.que_id 题目id
 * @return {*}
 */
 export async function $collectQues(params) {
  try {
    return await HTTP.post(`${PREFIX3}collect`, params);
  } catch (error) {
    return error;
  }
}
/*******
 * @Descripttion:批量添加题目
 * @Author: Dyf
 * @return {*}
 */
export async function $teaquestionAdds(params) {
  try {
    return await HTTP.post(`${PREFIX3}adds`, params);
  } catch (error) {
    return error;
  }
}

/*******
 *公式识别
 * @return {*}
 */
export async function $recognizeeduformula(params) {
  try {
    return await HTTP.post(`${PREFIX3}recognizeeduformula`, params);
  } catch (error) {
    return error;
  }
}
/*******
 *精细化排版
 * subject	数学
 * @return {*}
 */
export async function $recognizeedupaperstructed(params) {
  try {
    return await HTTP.post(`${PREFIX3}recognizeedupaperstructed`, params);
  } catch (error) {
    return error;
  }
}

/*******
 *切题识别
 * @return {*}
 */
 export async function $recognizeedupapercut(params) {
  try {
    return await HTTP.post(`${PREFIX3}recognizeedupapercut`, params);
  } catch (error) {
    return error;
  }
}
/*******
 *切题识别
 * @return {*}
 */
 export async function $ocradd(params) {
  try {
    return await HTTP.post(`${PREFIX3}ocradd`, params);
  } catch (error) {
    return error;
  }
}

/*******
 *试卷识别
 * @return {*}
 */
 export async function $recognizeedupaperocr(params) {
  try {
    return await HTTP.post(`${PREFIX3}recognizeedupaperocr`, params);
  } catch (error) {
    return error;
  }
}

/*******
 *题目识别
 * @return {*}
 */
 export async function $recognizeeduquestionocr(params) {
  try {
    return await HTTP.post(`${PREFIX3}recognizeeduquestionocr`, params);
  } catch (error) {
    return error;
  }
}


/* 试卷管理 */

/*******
 * @Descripttion: 试卷列表
 * @Author: Dyf
 * @param {number} type 试卷类型 1. 我的  2. 校本  3. 平台
 * @param {object} params
 * @param {number} pagesize 每页条数
 * @return {*}
 */
export async function $exampaperList(type, params, pagesize = PAGE_SIZE) {
  let prefix = "";
  switch (Number(type)) {
    case 1:
      prefix = "tea";
      break;
    case 2:
      prefix = "sch";
      break;
    case 3:
      prefix = "pla";
      break;
    default:
      break;
  }
  let url = `/teacher/${prefix}paper/list`;
  try {
    return await HTTP.get(
      url,
      { ...params, pagesize },
      { load: true, loadEl: ".page-inner" }
    );
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 试卷详情
 * @Author: Dyf
 * @param {number} type 试卷类型 1. 我的  2. 校本  3. 平台
 * @param {number} id 试卷id
 * @return {*}
 */
export async function $exampaperDetail(type, id) {
  try {
    let prefix = "";
    let params = {};
    switch (Number(type)) {
      case 1:
        prefix = "tea";
        params.tepap_id = id;
        break;
      case 2:
        prefix = "sch";
        params.scpap_id = id;
        break;
      case 3:
        prefix = "pla";
        params.plpap_id = id;
        break;
      default:
        break;
    }
    let url = `/teacher/${prefix}paper/info`;
    return await HTTP.post(url, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 加入我的试卷
 * @Author: Dyf
 * @param {number} type 试卷类型 1. 校本  2. 平台
 * @param {number} id 试卷id
 * @return {*}
 */
export async function $exampaperInsert(type, id) {
  try {
    let prefix = "";
    let params = {};
    switch (Number(type)) {
      case 1:
        prefix = "sch";
        params.scpap_id = id;
        break;
      case 2:
        prefix = "pla";
        params.plpap_id = id;
        break;
      default:
        break;
    }
    let url = `/teacher/${prefix}paper/insert_my`;
    return await HTTP.post(url, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 共享试卷
 * @Author: Dyf
 * @param {number} tepap_id 试卷id
 * @return {*}
 */
export async function $shareExampaper(tepap_id) {
  try {
    return await HTTP.post(`${PREFIX4}share_paper`, { tepap_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 新增试卷
 * @Author: Dyf
 * @param {string} params
 * @return {*}
 */
export async function $addExampaper(params) {
  try {
    return await HTTP.post(`${PREFIX4}add`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 编辑试卷
 * @Author: Dyf
 * @param {string} params
 * @return {*}
 */
export async function $editExampaper(params) {
  try {
    return await HTTP.post(`${PREFIX4}edit`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 手动选题
 * @Author: Dyf
 * @param {number} params.syque_typ_id 题型id
 * @param {string} params.teque_title 题目标题
 * @param {number} params.pageindex 当前页
 * @param {number} pagesize 每页条数
 * @return {*}
 */
export async function $manualTopic(params, pagesize = PAGE_SIZE) {
  try {
    return await HTTP.post(`${PREFIX4}hand_move`, { ...params, pagesize });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 随机选题
 * @Author: Dyf
 * @param {number} params.syque_typ_id 题型id
 * @param {number} params.syque_typ_num 题型数量
 * @param {number} params.syque_typ_score 每题分数
 * @return {*}
 */
export async function $randomTopic(params) {
  try {
    return await HTTP.post(`${PREFIX4}random_move`, params);
  } catch (error) {
    return error;
  }
}


/* 校内练习 */
/*******
 * @Descripttion: 题目列表
 * @Author: Dyf
 * @param {object} params
 * @param {number} pagesize 每页显示条数
 * @return {*}
 */
export async function $practiceList(params, pagesize = PAGE_SIZE) {
  try {
    return await HTTP.post(
      `${PREFIX5}list`,
      { ...params, pagesize },
      { load: true }
    );
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 学生列表
 * @Author: Dyf
 * @param {number} tecla_id	 课堂id
 * @return {*}
 */
export async function $practiceStudents(tecla_id) {
  try {
    return await HTTP.post(`${PREFIX5}getPracticeUser`, { tecla_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 学生答题信息
 * @Author: Dyf
 * @param {number} tecla_id 课堂id
 * @param {number} stuser_id 学生id
 * @return {*}
 */
export async function $studentAnswerPractice(tecla_id, stuser_id) {
  try {
    return await HTTP.post(`${PREFIX5}getPracticeQuestion`, { tecla_id, stuser_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 判断作答是否正确
 * @Author: Dyf
 * @param {number} teles_pra_id 题目id
 * @param {number} tepralog_id 手写板答题id
 * @param {number} type 判断类型 1：正确  2：错误  3：半对
 * @return {*}
 */
export async function $determinePractice(teles_pra_id, tepralog_id, type) {
  try {
    return await HTTP.post(`${PREFIX5}correct`, { teles_pra_id, tepralog_id, type });
  } catch (error) {
    return error;
  }
}


/*******
 * @Descripttion: 批阅完成
 * @Author: Dyf
 * @param {number} tecla_id 课堂id
 * @param {number} stuser_id 学生id
 * @return {*}
 */
export async function $finishReadPractice(tecla_id, stuser_id) {
  try {
    return await HTTP.post(`${PREFIX5}readover`, { tecla_id, stuser_id });
  } catch (error) {
    return error;
  }
}